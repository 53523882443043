import { authHeader } from './auth-header';
import JwtService from "@/helpers/authservice/jwt.service";
export const userService = {
    login,
    loginGoogle,
    restaurarContrasena,
    cambiarContrasena,
    verifyAuth,
    logout,
    register,
    getAll,
};

const urlApi = process.env.VUE_APP_APIURL;

function login(email, clave) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, clave })
    };

    return fetch(`${urlApi}/auth`, requestOptions)
        .then(handleResponse)
        .then(user => {
  
            // login successful if there's a jwt token in the response
            if (user.body.usuario.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user.body.usuario));
            }else if(user.status == 401){
                
            }
            return user.body.usuario;
        });
}
function loginGoogle(datos) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(datos)
    };

    return fetch(`${urlApi}/auth/google`, requestOptions)
        .then(handleResponse)
        .then(user => {
  
            // login successful if there's a jwt token in the response
            if (user.body.usuario.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user.body.usuario));
            }else if(user.status == 401){
                
            }
            return user.body.usuario;
        });
}

function restaurarContrasena(datos) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(datos)
    };

    return fetch(`${urlApi}/auth/restaurarContrasena`, requestOptions)
        .then(handleResponse)
        .then(user => {
  
            return user;
        });
}
function cambiarContrasena(datos) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(datos)
    };

    return fetch(`${urlApi}/auth/cambiarContrasena`, requestOptions)
        .then(handleResponse)
        .then(user => {
  
            return user;
        });
}
function verifyAuth() {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
        'Authorization': `Bearer ${JwtService.getToken()}` },
    };

    return fetch(`${urlApi}/auth`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.body.usuario.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user.body.usuario));
            }else if(user.status == 401){
                return null
            }
            return user.body.usuario;
        }).catch((error) => {
            logout()
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    return fetch(`/users/register`, requestOptions).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`/users`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
